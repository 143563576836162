export default[
    {
        id: 1,
        name:"Sabu",
        title: "Sabu Paudel",
        category:"Klankschalen Meditatie",
        description: "Mijn naam is Sabu Paudel, geboren en getogen in Nepal, het dak van de wereld en het geboorteland van Boeddha. Yoga en meditatie heb ik van huis uit met de paplepel ingegoten gekregen.",
        description1:"Door het volgen van yoga en klankschalen training en cursussen in Nepal heb ik niet alleen de kennis vergaard maar ook geleerd deze in de praktijk toe te passen. ",
        description2:"Ik woon inmiddels bijna twintig jaar in Purmerend.  ",
        link:'',
        coverImg: "sabi.jpg",
        tag:"",
        bgcolor:'',
        email:"sabspaudel@gmail.com",
        phone:"06-83710239",
        
    },
    {
        id: 2,
        name:"Kim",
        title: "Kim van Groningen",
        category:"Yoga",
        description: "Mijn naam is Kim van Groningen en ik ben geboren en getogen onder de rook van Amsterdam. Vanaf jonge leeftijd heb ik al mogen ervaren wat yoga kan betekenen voor lichaam en geest. Vooral de rust en ontspanning trok mij aan in wat yoga geeft. ",
        description1: "Mijn liefde voor reizen heb ik mogen combineren met het volgen van yoga docenten opleidingen, omdat ik meer verdieping zocht in de werking van yoga.",
        description2:"Door mijn achtergrond in de verpleegkunde ben ik altijd al bezig met gezondheid en vitaliteit en vind ik het ontzettend interessant hoe yoga kan helpen om lichamelijk en geestelijk in balans te komen en te blijven. Wat ik belangrijk vind in de yogalessen is aandacht voor jezelf, je lichaam, en de ademhaling. Dit zorgt ervoor dat je beter gaat voelen wat goed voor je is.",
        link:'',
        coverImg: "kim.webp",
        tag:"",
        bgcolor: '',
        email:"info@sukiyoga.nl",
        phone:" 06-34425804",
    }
]