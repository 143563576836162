import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';


const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  width: '100%',
  maxHeight: '100%',
});

const style = {
  position: 'absolute',
  overflow:'scroll',
  height:'100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #fff',
  boxShadow: 24,
  p: 4,
  borderRadius: '2px' 
};

function sendEmail(email) {
  var subject = 'From https://klankschalenyoga.nl: ';
  document.location = "mailto:"+email+"?subject="+subject;
}

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="/">
        Klankschalen Yoga
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



export default function Footer() {
  
  return (     
        <Box sx={{ bgcolor: '#c8e1cc',p:6, width: '100%' }} component="footer">  
          <Typography variant="subtitle1" align="center" color="text.secondary">
     
        <span className='next-line'><b>Contact</b>: Sabu Paudel</span>
        <span className='next-line'><IconButton href="tel:+31683710239"><LocalPhoneIcon /> </IconButton>: 06-83710239</span>
        <span className='next-line'><IconButton onClick={() => sendEmail('yogaklankschalen@gmail.com')}  ><MailOutlineIcon /></IconButton>: yogaklankschalen@gmail.com</span>
        <br/>
        <span className='next-line'> Westerstraat 31, 1441 AR Purmerend</span>
        <span className='next-line'><Copyright /></span>
     
        </Typography>

       
        
        
        </Box>

        
          
  );
}