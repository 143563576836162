import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { IconButton } from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxHeight: '100%',
  });

  function sendEmailContactPerson(email) {
    var subject = 'From https://klankschalenyoga.nl: ';
    document.location = "mailto:"+email+"?subject="+subject;
  }

export default function Plaats(props) {
    return (
        <Box sx={{bgcolor: 'background.paper',pt: 8,}} >
        <Container>
            {props.id}
            <div className="kyogaDiv" >
            <Typography  
                            component="h1"
                            variant="h4"
                            align="center"
                            color="text.primary"
                            paddingBottom={3}
                            
                            >
       
      </Typography>
      <Typography  component="div" align="left" color="text.secondary" paragraph>
            <div className="kyogaDiv">
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            <Typography className="kyogaH1">In Amsterdam</Typography>
            <Img alt="singing bowl yoga" referrerPolicy="no-referrer" src="../images/amsterdam-flyer.jpg" />
            <Typography className="kyogaH1">In Purmerend</Typography>
            <Img alt="singing bowl yoga" referrerPolicy="no-referrer" src="../images/klankschaal-flyer-new.png" />
            <Typography className="kyogaH1">In Utrecht</Typography>
            <Img alt="singing bowl yoga" referrerPolicy="no-referrer" src="../images/flyer-utrecht.png" />
             <Typography className="kyogaH1">Klankschalen meditatie Workshop</Typography>
            <Img alt="singing bowl yoga" referrerPolicy="no-referrer" src="../images/klankschaal-workshop.jpg" />
            </Typography>
           
            </div>      
        </Typography>
            </div>
           
            
        </Container>
        </Box>
    );
  }