import React from 'react';
import yogaData from './components/moreAboutYogaData';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';

  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxHeight: '100%',
  });

const voordelen = [
    {
        id:'1',
        text:'Bereik een diepe slaap en totale ontspanning',
    },
    {
        id:'2',
        text:'Bereik een diepe meditatieve staat',
    },
    {
        id:'3',
        text:' Verjong je vermoeide lichaam',
    },
    {
        id:'4',
        text:'Breng je chakras in balans',
    },
    {
        id:'5',
        text:'Verminder pijn en depressie',
    },
    {
        id:'6',
        text:'Verbeter de mentale helderheid',
    },
]

export default function AboutYoga() {
    return (
        <Box sx={{bgcolor: 'background.paper',}} >
             <Img alt="singing bowl" src="../klankschaal.png" />
        <Container>
        <Grid  spacing={2} >  
                {yogaData.map((item) => (
                            <>
                            <Typography
                            
                            component="h1"
                            variant="h4"
                            align="left"
                            color="text.primary"
                            paddingBottom={3}
                            
                            >
                        <Typography className="kyogaH1" variant="h4">{item.title} </Typography> 
                        </Typography>
                            <Typography  component="div" align="left" color="text.secondary" paragraph>
                            <div className="kyogaDiv">
                                {item.description}
                            </div>
                            <div className="kyogaDiv">
                                {item.description1}
                            </div>
                            <div className="kyogaDiv">
                                {item.description2}
                            </div>
                           
                        </Typography>
                            </>
                   
                 ))}
                 
                            <Typography  component="div" align="left" paragraph>
                            <div className="kyogaDiv" >
                                De voordelen van een klankschalenmassage zijn als volgt:
                            </div>
                            <List sx={{color: 'success.main', backgroundColor:'#f5f5f5','& .css-10hburv-MuiTypography-root': {
      fontSize:26, fontWeight:'320'}, }}>
                            {voordelen.map((item) => (
                              
                                 <ListItem >
                                 <ListItemButton sx={{cursor:'default'}} >
                                   <ListItemIcon>
                                     <CircleIcon sx={{ fontSize: 10, color: 'success.main' }}/>
                                   </ListItemIcon>
                                   <ListItemText primary={item.text} />
                                 </ListItemButton>
                               </ListItem>
                            ))}
                                
                            </List>
                           
                            
                        </Typography>

                </Grid>
                </Container>
            </Box>
    );
  }