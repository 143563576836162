import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import Lessons from './components/Lessons';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Services() {
    return (
        <Box sx={{bgcolor: 'background.paper',pt: 8,}} >
        <Container>
            <div className="kyogaDiv" >
            <Typography
                            
                            component="h1"
                            variant="h4"
                            align="center"
                            color="text.primary"
                            paddingBottom={3}
                            
                            >
                        <Typography className="kyogaH1" variant="h4">   Waar, wanneer en wat kost het?
 </Typography> 
      </Typography>
      <Typography  component="div" align="left" color="text.secondary" paragraph>
            <div className="kyogaDiv">
            De sessies zijn bedoeld voor iedereen die met yoga & meditatie willen ontspannen en meer balans in hun drukke leven willen, ongeacht leeftijd, culturele en etnische achtergrond. Afhankelijk van de aanmeldingen kunnen groepen naar leeftijd worden ingedeeld.
            <p>
            De yoga sessies worden op maandag- en dinsdagavond en op zondagmiddag verzorgd op verschillende locaties in Purmerend en daarbuiten.
            </p>
            </div>
                            
        </Typography>
            </div>
            <Lessons/>
            
        </Container>
        </Box>
    );
  }