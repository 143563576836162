export default [
    {
        id: '1',
        door: "Zentastic Vibes",
        description: "Sabu Paudel is een ware meesteres in het creëren van een rijk gevulde sound bath, waarbij je diep ontspant en eventjes jezelf de tijd gunt om volledig ondergedompeld te worden in de wondere wereld van sound healing. Ben je in de buurt van Utrecht of Purmerend? Trakteer jezelf dan eens op een sound healing sessie van Sabu Paudel van Klankschalenyoga",
    },
    {
        id: '2',
        door: "Devi Robin Sobas",
        description: "Sound healing is absolutely wonderful. I’m also a part of Sabu Paudel’s sound healing session. I can recommend it for  everybody.  ",
    },
    {
        id: '3',
        description: "Altijd heerlijk ontspanning en hervind innerlijke rust. Lekker slapen en helemaal tot je rust komen.",
        door: "Anja Plenter-Stol",
    },
    {
        id: '4',
        description: "Je klankschalen meditatie geeft echt rust, wat extra's wat ik maar lastig onder woorden kan brengen. Ik ben enorm geholpen in mijn zoektocht naar mentale en fysieke rust en balans. Ik blijf beter bij mezelf zowel in gevoel als doen. Ik kan iedereen aanraden.",
        door: "Sandra Kok",
    }
    
    
]