import React from 'react';
import ReactDOM from 'react-dom';
import { Formik, Field, Form } from 'formik';
import { Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

function sendEmail(message) {
  var email = 'priti0505@gmail.com';
  var subject = 'Klaankschalen Yoga Contact Form';
  var emailBody = message;
  document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxHeight: '100%',
  });

  const sessionDays = [
    {
      value: 'maandag',
      label: 'Maandag',
    },
    {
      value: 'dinsdagavond',
      label: 'Dinsdagavond',
    },
    {
      value: 'zondagmiddag',
      label: 'Zondagmiddag',
    }
  ];

  const sessionType = [
    {
        value: 'Single',
        label :'One to One Massage and Relaxation'
    },
    {
        value:'Group',
        label:'Group Relaxation'
    }
]



export default function Contact() {
    return (
        <Box
          sx={{
          bgcolor: 'background.paper',
          pt: 6,
          }}
        >
        <Img alt="singing bowl" src="../singingbs.jpg" />
        <Box sx={{bgcolor: 'background.paper',pt: 8,}} >
          <Container maxWidth="lg">
            <div className="kyogaDiv" >
              <Typography
                  component="h1"
                  variant="h4"
                  align="left"
                  color="text.primary"
                  paddingBottom={3}
              >
                  <Typography className="kyogaH1" variant="h4"> Boek een sessie</Typography> 
              </Typography>
              <Typography className="kyogaDiv"  gutterBottom variant="subtitle1"  align="justify" color="text.secondary" paragraph> 
                <iframe className="contact--form" src="https://docs.google.com/forms/d/e/1FAIpQLSc-VC0OyVi2osGd9Ec8ScTJtSXHPb3ERvoOMylTqLVQTrIstg/viewform?embedded=true" width="740" height="1451" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>  
              </Typography>
            </div>
          </Container>
        </Box> 
        </Box>
        
    );
}