export default [
        {
            id: '1',
            title: "Individuele meditatie en ontspanning",
            description: "Duur: 1 uur",
            description1:"",
            coverImg: "images/new_photos/PHOTO-2023-09-06-09-34-08.jpg",
            price:"€25 per sessie",
            time:"Op afspraak",
            location:"Purmerend, Amsterdam & omgeving",
            city:" "
           
       
        },
        {
            id: '2',
            title: "Groepsyoga meditatie en ontspanning",
            description: "Duur: 1 uur",
            description1:"",
            coverImg: "images/new_photos/PHOTO-2023-09-06-09-34-05.jpg",
            price:"€5 per person per sessie",
            time:"Woensdagen, 15:00-16:00 uur",
            location:"Triton 73",
            city:'Purmerend'
       
        },
        {
            id: '3',
            title: "Klankschalen Meditatie in Amsterdam ",
            description: "Duur: 1 uur",
            description1:"",
            coverImg: "images/new_photos/klankschaal-groep.png",
            price:"€5 per person per sessie",
            time:"Woensdagen, 13:00-14:00 ",
            location:"Buurtcentrum De Meeuw, Motorwal 300 ",
            city: 'Amsterdam'        
       
        },
        {
            id: '4',
            title: "Klankschalen Meditatie in Utrecht",
            description: "Duur: 1 uur",
            description1:"",
            coverImg: "images/new_photos/PHOTO-2023-09-06-09-34-00.jpg",
            price:"€2,5 p.p. overdag sessie. €5 p.p. avond sessie.",
            time:"Maandagen bekijk  sessies in de flyer",
            location:"Laurierweg 104",
            city:'Utrecht'
       
        },
        {
            id: '5',
            title: "Workshop Klankschalenyoga",
            description: "Duur: 1.5 uur",
            description1:"",
            coverImg: "images/workshop.jpg",
            price:"€ 150 per workshop",
            time:"Op afspraak",
            location:"Noord Holland, Zuid Holland en Utrecht",
           city: 'Overal'
       
        },
        
]