import React from 'react';
import aboutUsData from './aboutUsData';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { Link } from '@mui/material';
import Container from '@mui/material/Container';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function landingTeam() {
    return (
      <Container>
         <Typography
                            component="h1"
                            variant="h4"
                            align="left"
                            color="text.primary"
                            paddingBottom={3}
                            
                            >
                        <Typography className="kyogaH1" variant="h4">  Wie zijn wij?</Typography> 
      </Typography>
      
        <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 8,
          pb: 6,
          
        }}
        className="kyoga-cards-list" 
      >   
        {aboutUsData.map((item, index) => (
         <Grid container direction="column"
         alignItems="center"
         justifyContent="center"
         rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Item>
            <Card sx={{ minWidth: 275 }} >
              <Link href={"/team#"+item.name} underline="none">
            <CardHeader
                avatar={
                  <Avatar   alt={item.title}
                  src={item.coverImg}
                  sx={{ width: 170, height: 170 }}>
                    
                  </Avatar>
                }
              
                title={item.title}
                subheader={item.category}
              />
              </Link>
                  <CardContent>
                    
                    
                    {/* <Typography variant="body2">
                      well meaning and kindly.
                      <br />
                      {'"a benevolent smile"'}
                    </Typography> */}
                  </CardContent>
                </Card> 
            </Item>
          </Grid>
        </Grid>
        ))}
         
        </Box>
        </Container>
    );
  }