import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Home';
import Services from './Services';
import Contact from './Contact';
import Team from './Team';
import AboutYoga from './AboutYoga';
import Servicesmore from './Services-more' ;
import Flyer from './Flyer' ;

export default function App() {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <Navbar />
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Home/>} />
            <Route path='/aboutYoga' element={<AboutYoga/>} />
            <Route path='/sessies' element={<Services/>} />
            <Route path='/team' element={<Team/>} />
            <Route path='/boeken' element={<Contact/>} />
            <Route path='/flyer' element={<Flyer/>} />
            <Route exact path='/:id' element={<Servicesmore/>} />
          </Routes>
        </BrowserRouter>
        <Footer />
    </ThemeProvider>
  );
}

