export default[
    
    
    {
        id: 1,
        title: "Wat zijn klankschalen?",
        description: " Klankschalen zijn gemaakt van verschillende soorten metaal of kristallen. Ze kunnen klein, groot, dik of dun van formaat zijn, elk produceert een eigen geluid en heeft een eigen effect.",
        description1: " Ons lichaam bestaat voor circa 70% uit water. De trillingen van de klankschaal zetten het water in ons lichaam in beweging, wat voor ontspanning zorgt. Daardoor kunnen stress en spanning worden losgelaten. ",
        description2: " Klankschalen bestaan al heel lang en werden destijds voor verschillende doeleinden gebruikt als kommen om uit te eten, als instrument om muziek mee te maken en als schaal om offers te brengen in Nepal en India. Maar tegenwoordig zijn klankschalen overal ter wereld te vinden en worden ze voor yoga, meditatie en healings gebruikt. ",
        link:"",
        coverImg: "",
        tag:"",
        bgcolor: '',
       
    },
    {
        id: 2,
        title: "Waarvoor doe je het?",
        description: " De klankschalen worden vervolgens op verschillende delen van uw lichaam geplaatst terwijl ze worden getikt om verschillende geluiden en trillingen te creëren, zodat vitale energie vrij kan stromen.  Lange, vloeiende massageslagen en druk complimenteren de vibratie om een ​​dieper gevoel van ontspanning te bereiken.",
        description1: "klankschalenmassage is een alternatieve vorm van fysieke en emotionele therapie die zijn eigen procedures heeft, dus de resultaten kunnen variëren afhankelijk van de verschillende soorten mensen. ",
        description2: " Als jij het type persoon bent dat openstaat om nieuwe dingen te proberen, dan is dit iets waarin je misschien geïnteresseerd bent om het te doen.",
        link:"",
        coverImg: "",
        tag:"",
        bgcolor: '',
       
    },
    
]