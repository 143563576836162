import React from "react";
import Services from "./Services";
import LandingTeam from './components/LandingTeam';
import Reviews from './components/Reviews';
import StandardImageList from './components/ImageList';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import Fab from '@mui/material/Fab';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import Backdrop from '@mui/material/Backdrop';
import SelfImprovementIcon from '@mui/icons-material/SelfImprovement';
import CloseIcon from '@mui/icons-material/Close';
import Link from '@mui/material/Link';

import Grid from '@mui/material/Grid';
import ButtonBase from '@mui/material/ButtonBase';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    width: '100%',
    maxHeight: '100%',
  });

  const style = {
    position: 'absolute',
    overflow:'scroll',
    height:'100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #fff',
    boxShadow: 24,
    p: 4,
    borderRadius: '2px' 
  };

  const handleClick = () => {
    console.info('You clicked the Chip.');
  };

export default function Home() {
    const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [showModal, setShowModal] = React.useState(false);
  const openModal = () => {
    setShowModal(showModal => !showModal);
  };

    return(
        
        <Box>
            {/* landing photo */}
            <Img alt="singing bowl" src="../singing-bowls.jpg" />  
            <div maxWidth="lg"  >
            <Typography  component="div" align="left" color="text.secondary" paragraph  p={4} sx={{bgcolor:'#c8e1cc', mt:'8', mb:'8'}}>
                <Container className="kyogaDiv">
                    De sessies bestaan uit lichte yoga oefeningen die jong en oud mee kunnen doen. 
                    Aansluitend doen we een meditatie oefening begeleid met klankschalen. 
                    De harmoniserende klanken zorgen ervoor dat je makkelijker in het hier en nu komt, 
                    dat gedachten stilvallen en je dieper kunt ontspannen. 
                    <p>
                    In Nederland leidt iedereen een druk bestaan met werk, gezin, familie en sociale verplichtingen. Er moeten veel ballen in de lucht worden gehouden. Dit kan je het gevoel geven dat je geleefd wordt en weinig tijd hebt voor jezelf. In de hectiek is het juist heel belangrijk om echt te ontspannen en om voldoende oplaadmomenten in te bouwen. Yoga en meditatie zijn hier erg geschikt voor en de klankschalen zorgen voor een diepe ontspanning en innerlijke rust. Graag laat ik jou dit zelf ervaren!
                    </p>
                    <p>
                        Als herboren ga je weer naar huis!   
                    </p>    
                </Container>
            </Typography>
            <Container>
                <Services/>
                
               <LandingTeam/>

               <Typography
                            component="h1"
                            variant="h4"
                            align="left"
                            color="text.primary"
                           
                            >
                        <Typography className="kyogaH1" variant="h4">  Recencies</Typography> 
      </Typography>
               <Reviews/>
               <Typography
                            component="h1"
                            variant="h4"
                            align="left"
                            color="text.primary"
                            paddingBottom={2}
                            
                            >
                        <Typography className="kyogaH1" variant="h4">  In de pers</Typography> 
      </Typography>
               <Typography  component="div" align="left" color="text.secondary" paragraph  p={4} sx={{bgcolor:'#c8e1cc', mt:'12', mb:'12'}}>

              
               <Grid container spacing={2}>
       
        <Grid item xs={12} sm container>
          <Grid item xs container direction="column" spacing={2}>
            <Grid item xs>
            <Typography component="div" variant="h5">
          <b><img src="/varnewslogo.png"  width= "50" height="50"  float="right" /> Nepalese gemeenschap in Leidsche Rijn</b>
          </Typography>
          <Typography variant="subtitle1" color="text.secondary" component="div">
          <b>Door Roberto Cancian</b> (<time class="font-light text-gray-400 text-base" datetime="2024-01-23T08:09:05.000">
            di 23 jan, 08:09
        </time>)
        <p>
        ‘Dit kan iedereen gebruiken. Het westerse leven is druk, het is goed om ontspanning te vinden en voldoende oplaadmomenten in te bouwen. De klankschalenmeditatie is laagdrempelig en zorgt voor een diepe ontspanning en innerlijke rust. We bereiken de doelgroep die het lastig heeft of moeite heeft om naar het reguliere aanbod te gaan,' geeft Shashi Paudyal aan. De 57-jarige Paudyal is al 33 jaar in Nederland maar blijft een Nepalees in hart en nieren. Met hulp van een aanvraag bij het Initiatieven Fonds, met ondersteuning door Sociaal Makelaar Lotte Klaverstijn, verwelkomt Sabu Paudel iedere maandag minimaal vijftien mensen bij de Klankschalenmeditatie. ‘Paudel is geboren en getogen in Nepal, het dak van de wereld en het geboorteland van Boeddha. Yoga en meditatie heeft zij van huis uit met de paplepel ingegoten gekregen. Daarnaast heeft ze diverse cursussen gevolgd in Nepal,' geeft Shashi Paudyal aan. Dat het aanslaat bevestigt Lotte Klaverstijn. ‘Het is een groot succes waar veel bewoners uit de buurt mee geholpen zijn,' benadrukt Klaverstijn. ‘We willen graag uitbreiden, mede omdat er meer vraag naar is. Ook uit andere wijken,' geeft Paudyal aan. In het dagelijks leven is de voorzitter van Stichting NDA werkzaam als tolk en vertaler. ‘Ik woon sinds 2007 in Leidsche Rijn. Daarvoor heb ik zestien jaar in Nijmegen gewoond, waar ik ook Beleids- en Bestuurswetenschappen studeerde. Organiseren is daaruit voortgekomen.'  

        </p>
        <p>

<Link href="https://www.varnws.nl/reader/21430/32539/nepalese-gemeenschap-in-leidsche-rijn#p=9" underline="hover">les meer</Link> 
</p>
          </Typography>
            </Grid>
          </Grid>
          <Grid item>
          <ButtonBase sx={{ width: 300}}>
            <Img alt="Klankschaal yoga in de pers" src="../in-de-pers.png" />
          </ButtonBase>
        </Grid>
        </Grid>
      </Grid>


               

                
            </Typography>
            <Typography align="center" sx={{ mb:4}}>
            
                <div className="kyogaDiv">
                    <Button className="kyoga-button" href="/boeken"  >
                    Ik meld mij aan 
            </Button>
                </div>
            </Typography>
           
           
            </Container>
            </div>
            <Typography sx={{ mb:4}}>
                <StandardImageList/>
                
            </Typography>
            <Fab
          color="secondary"
          sx={{
            position: 'fixed',
            bottom: (theme) => theme.spacing(4),
            right: (theme) => theme.spacing(4),
          }}
           variant="contained" onClick={handleOpen}
        >
          <SelfImprovementIcon />
        </Fab>
        <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Link href="#" onClick={handleClose}  style={{float:'right'}}><CloseIcon/></Link>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            <Typography className="kyogaH1">In Amsterdam</Typography>
            <Img alt="singing bowl yoga" src="../images/amsterdam-flyer.jpg" />
            <Typography className="kyogaH1">In Purmerend</Typography>
            <Img alt="singing bowl yoga" src="../images/klankschaal-flyer-new.png" />
            <Typography className="kyogaH1">In Utrecht</Typography>
            <Img alt="singing bowl yoga" src="../images/flyer-utrecht.png" />
            <Typography className="kyogaH1">Klankschalen meditatie Workshop</Typography>
            <Img alt="singing bowl yoga" src="../images/klankschaal-workshop.jpg" />
            </Typography>

            <Typography >
              <Link href="#" onClick={handleClose} underline="hover">Close</Link></Typography> 
          </Box>
        </Fade>
      </Modal>
        </Box>
        
    );
}


