import React, { useEffect } from 'react';
import aboutUsData from './components/aboutUsData';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'justify',
  color: theme.palette.text.secondary,
}));
function sendEmail(email) {
  var subject = 'Klaankschalen Yoga Contact ';
  document.location = "mailto:"+email+"?subject="+subject;
}

let setTimeoutId;

function useScrollIntoViewTeamMembers() {
  useEffect((e) => {
    // so scrollInto view only runs once
    if (setTimeoutId) {
      clearTimeout(setTimeoutId)
    }

    setTimeoutId = setTimeout(() => {
      const id = window.location.hash;
      const element = id && document.getElementById(id.substring(1));
      
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }, 500);
    
  }, [window.location.hash])
}

export default function Team() {  
  useScrollIntoViewTeamMembers();

    return (
        <Box sx={{bgcolor: 'background.paper',pt: 8,}} >
        <Container>
        <div className="kyogaDiv" >
            <Typography className="kyogaH1" variant="h4">Wie zijn wij? </Typography> 
        </div>
        <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 8,
          pb: 6,
          
        }}
        className="kyoga-cards-list" 
      >  
      
        {aboutUsData.map((item, index) => (
        
         <Grid container direction="column"
         alignItems="center"
         justifyContent="center"
         rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}>
            <Item >
            <Card>
           
                 <div id={item.name}></div>
                  <CardMedia name={item.name}
                    component="img"
                    alt={item.title}
                    height="380"
                    image={item.coverImg}
                  />
                  <CardContent className="kyogaDiv">  
                    <Typography gutterBottom variant="h5" component="div">
                    {item.title}
                    </Typography>
                    <Typography variant="body" color="text.secondary"  component="div">
                    {item.description}
                    </Typography>
                    <Typography variant="body" color="text.secondary"  component="div">
                    {item.description1}
                    </Typography>
                    <Typography variant="body" color="text.secondary"  component="div">
                    {item.description2}
                    </Typography>
                    <a size="small" className="kyoga-link" > Telefoon:{item.phone}</a><br />
                    <a size="small" className="kyoga-link" href="" onClick={() => sendEmail(item.email)} sx={{cursor: 'pointer'}} component="button"> Email:{item.email}</a>
                  </CardContent>
                </Card> 
            </Item>
          </Grid>
        </Grid>
        ))}
         
        </Box>
        </Container>
        </Box>
    );
  }