import React from 'react';
import reviewsData from './reviewsData';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';



const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const style = {
  position: 'absolute',
  overflow:'scroll',
  height:'100%',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid #fff',
  boxShadow: 24,
  p: 4,
  borderRadius: '2px' 
};




export default function Reviews() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };



    return (
        <Box
        sx={{
          bgcolor: 'background.paper',
          pt: 6,
          pb: 6,
          
        }}
        className="kyoga-cards-list" 
      >   
      
      <Grid container 
         alignItems="center"
         
         rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {reviewsData.map((item, index) => (       
          <Grid item xs={12} sm={6} md={6} lg={6}>

            
            <Item >
            <Card>
                  
                  <CardContent className="kyogaDiv">  
                  <Typography gutterBottom variant="h5" component="div">
                  {item.title}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary"  component="div">
                        {item.description}
                        </Typography>
                        <Typography variant="h6"  component="div">
                          Door: {item.door}
                        </Typography>
                  </CardContent>
                </Card> 
            </Item>
          </Grid>
       
        ))}
          </Grid>
        </Box>
    );
  }