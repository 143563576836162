import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import { Link } from '@mui/material';

const ITEM_HEIGHT = 58;
const pages = [
  
  {
    id: "2",
    title: "Over Yoga",
    link:"/aboutYoga",
    className:""
  },
  {
    id: "3",
    title: "Sessie",
    link:"/sessies",
    className:""
  },
  {
    id: "4",
    title: "Team",
    link:"/team",
    className:""
  },
  {
    id: "5",
    title: "Nu Boeken",
    link:"/boeken",
    className:"kyoga-button"
  },
  {
    id: "6",
    title: "Flyer",
    link:"/flyer",
    className:" "
  }
]

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            
          >
              <Link href="/" underline="none" className="kyoga-header-text" ><img src='../kyogalogo.png' width="400"  alt="Logo" /></Link>

          </Typography>

          <Box sx={{ flexGrow: 0,  display: { xs: 'flex', md: 'none' } }} >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              className="kyoga-MuiSvgIcon-root"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', color:'#198d58', md: 'none' },
              }}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '30ch',
                  ml:0
                },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.title} onClick={handleCloseNavMenu} divider="true" >
                  <Button underline="none" className={"kyoga-link " + page.className} href={page.link} textAlign="center">
                    {page.title}
                  </Button>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          
          <Box sx={{ flexGrow: 1, ml:12, display: { xs: 'none', md: 'flex'} }} >
            {pages.map((page) => (
              <Button
                className={"kyoga-link "+ page.className}
                key={page.title}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color:'#198d58', display: 'block' }}
                href={page.link}
              >
                {page.title}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Facebook">
              <IconButton href='https://www.facebook.com/klankschalenyoga' sx={{ p: 0, fontSize:'10px' }}>
                <FacebookIcon  />
              </IconButton>
            </Tooltip>
            <Tooltip title="Instagram">
              <IconButton href='https://www.instagram.com/klankschalenyoga.nl/' sx={{ p: 0 }}>
                <InstagramIcon  />
              </IconButton>
            </Tooltip>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;